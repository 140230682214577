import { createActionGroup, props } from "@ngrx/store";
import { BackendErrorsInterface } from "@ushauri/backend-error-messages";
import { GeneralSubscription } from "../../model/general-subscription.model";
import { AddGeneralSubscriptionRequest } from "../../types/addGeneralSubscriptionRequest";
import { EditGeneralSubscriptionRequest } from "../../types/editGeneralSubscriptionRequest";
import { FetchGeneralSubscriptionRequest } from "../../types/fetchGeneralSubscriptionRequest";
// import { AddGeneralSubscriptionRequest } from "../../types/addGeneralSubscriptionRequest";
// import { EditGeneralSubscriptionRequest } from "../../types/editGeneralSubscriptionRequest";
// import { FetchGeneralSubscriptionRequest } from "../../types/fetchGeneralSubscriptionRequest";

export const generalSubscriptionActions = createActionGroup({
    source: "GeneralSubscription",
    events:{

        AddGeneralSubscription : props<{request:AddGeneralSubscriptionRequest}>(),
        'Add GeneralSubscription Success': props<{generalSubscription:GeneralSubscription}>(),
        'Add GeneralSubscription Failure': props<{errors:BackendErrorsInterface}>(),
        EditGeneralSubscription : props<{request:EditGeneralSubscriptionRequest}>(),
        'Edit GeneralSubscription Success': props<{generalSubscription:GeneralSubscription}>(),
        'Edit GeneralSubscription Failure': props<{errors:BackendErrorsInterface}>(),
        FetchGeneralSubscription : props<{request:FetchGeneralSubscriptionRequest}>(),
        'Fetch GeneralSubscription Success': props<{generalSubscription:GeneralSubscription}>(),
        'Fetch GeneralSubscription Failure': props<{errors:BackendErrorsInterface}>()
    }
});
