import { Action, createFeature, createReducer, on } from "@ngrx/store";
import { GeneralSubscriptionStateInterface } from "../types/GeneralSubscriptionStateInterface";
import { generalSubscriptionActions } from "./actions/general-subscription.actions";


const initialState: GeneralSubscriptionStateInterface = {
    isSubmittingAddGeneralSubscription: false,
    currentGeneralSubscription : null,  
    generalSubscriptionAdded : null,    
    isSubmittingFetchGeneralSubscription: false,     
    generalSubscriptionFetched : null,   
    isSubmittingEditGeneralSubscription: false,    
    generalSubscriptionEdited : null,
    validationErrors:  null
}

const generalSubscriptionFeature = createFeature({
    name:'general_subscription',
    reducer: createReducer(initialState,
        on(generalSubscriptionActions.addGeneralSubscription, (state): GeneralSubscriptionStateInterface =>
            ({
                ...state,
                isSubmittingAddGeneralSubscription: true,
                validationErrors: null
            })),
            on(generalSubscriptionActions.addGeneralSubscriptionSuccess, (state, action): GeneralSubscriptionStateInterface =>
            ({
                ...state,
                isSubmittingAddGeneralSubscription: false,
                validationErrors: null,
                generalSubscriptionAdded: true,
                currentGeneralSubscription: action.generalSubscription
            })),
        
            on(generalSubscriptionActions.addGeneralSubscriptionFailure, (state, action): GeneralSubscriptionStateInterface =>
            ({
                ...state,
                isSubmittingAddGeneralSubscription: false,
                validationErrors: action.errors,
                generalSubscriptionAdded: false,
                currentGeneralSubscription: null
            })),
            on(generalSubscriptionActions.fetchGeneralSubscription, (state): GeneralSubscriptionStateInterface =>
                ({
                    ...state,
                    isSubmittingFetchGeneralSubscription: true,
                    validationErrors: null
                })),
                on(generalSubscriptionActions.fetchGeneralSubscriptionSuccess, (state, action): GeneralSubscriptionStateInterface =>
                ({
                    ...state,
                    isSubmittingFetchGeneralSubscription: false,
                    validationErrors: null,
                    generalSubscriptionFetched: true,
                    currentGeneralSubscription: action.generalSubscription
                })),
            
                on(generalSubscriptionActions.fetchGeneralSubscriptionFailure, (state, action): GeneralSubscriptionStateInterface =>
                ({
                    ...state,
                    isSubmittingFetchGeneralSubscription: false,
                    validationErrors: action.errors,
                    generalSubscriptionFetched: false,
                    currentGeneralSubscription: null
                })),
                on(generalSubscriptionActions.editGeneralSubscription, (state): GeneralSubscriptionStateInterface =>
                    ({
                        ...state,
                        isSubmittingEditGeneralSubscription: true,
                        validationErrors: null
                    })),
                    on(generalSubscriptionActions.editGeneralSubscriptionSuccess, (state, action): GeneralSubscriptionStateInterface =>
                    ({
                        ...state,
                        isSubmittingEditGeneralSubscription: false,
                        validationErrors: null,
                        generalSubscriptionEdited: true,
                        currentGeneralSubscription: action.generalSubscription
                    })),
                
                    on(generalSubscriptionActions.editGeneralSubscriptionFailure, (state, action): GeneralSubscriptionStateInterface =>
                    ({
                        ...state,
                        isSubmittingEditGeneralSubscription: false,
                        validationErrors: action.errors,
                        generalSubscriptionEdited: false,
                        currentGeneralSubscription: null
                    }))  
    )
});

export const {
    name: generalSubscriptionsFeatureKey,
    reducer: generalSubscriptionsReducer,
    selectGeneralSubscriptionAdded,
    selectGeneralSubscriptionEdited,
    selectGeneralSubscriptionFetched,
    selectCurrentGeneralSubscription,
    selectIsSubmittingAddGeneralSubscription,
    selectIsSubmittingEditGeneralSubscription,
    selectIsSubmittingFetchGeneralSubscription,
    selectValidationErrors
}=generalSubscriptionFeature;

